<template>
  <v-app>
    <v-container class="mt-4">
      <h5 class="display-1">Add New Claim</h5>
      <p>
        <span>Add a new claim record to the claims processing sub-system.</span>
      </p>
      <p>
        <strong>Please select a batch name to get started</strong>
        <small>&nbsp;or create a new batch.</small>
      </p>
      <v-row justify="start">
        <v-col
          cols="8"
          class="p-0 d-flex align-items-center justify-content-start"
        >
          <v-col cols="6">
            <v-select
              :items="getBatchNames"
              outlined
              color="green"
              class="mt-3"
              v-model="claim_batch"
            ></v-select>
          </v-col>

          <v-col cols="3">
            <v-btn
              type="button"
              medium
              color="green darken-4"
              class="text-capitalize white--text"
              :to="{ name: 'create-batch' }"
              >create new batch</v-btn
            >
            <small>Batch does not exist yet?&nbsp;</small>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-container>
        <v-row>
          <v-col cols="12" class="mb-6">
            <v-form ref="form" @submit.prevent="handleClaimSubmission">
              <v-card flat>
                <!-- patient detail field -->
                <v-row justify="start" align="center" class="mt-4 mr-auto">
                  <v-col cols="12" class="green darken-2">
                    <small class="text-uppercase white--text"
                      >Patient's details</small
                    >
                  </v-col>
                </v-row>
                <v-row justify="start" align="stretch">
                  <v-col cols="8">
                    <!-- Surname -->
                    <v-row
                      justify="start"
                      align="stretch"
                      class="border border-secondary rounded-0"
                    >
                      <v-col cols="4" class="green lighten-3">
                        <small class="text-uppercase black--text"
                          >Surname</small
                        >
                      </v-col>
                      <v-col cols="8" class="d-flex">
                        <v-text-field
                          v-model="claim.last_name"
                          autocomplete
                          placeholder="Enter surname (readonly)"
                          required
                          outlined
                          readonly
                          :rules="validationRule('Surname is required')"
                          class="w-100 mb-0"
                          color="green darken-4"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- First name -->
                    <v-row
                      justify="start"
                      align="stretch"
                      class="border border-secondary rounded-0"
                    >
                      <v-col cols="4" class="green lighten-3">
                        <small class="text-uppercase black--text"
                          >First name</small
                        >
                      </v-col>
                      <v-col cols="8" class="d-flex">
                        <v-text-field
                          v-model="claim.first_name"
                          autocomplete
                          placeholder="Enter firstname (readonly)"
                          required
                          outlined
                          readonly
                          :rules="validationRule('First name is required')"
                          class="w-100 mb-0"
                          color="green darken-4"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Date of birth -->
                    <v-row
                      justify="start"
                      align="stretch"
                      class="border border-secondary rounded-0"
                    >
                      <v-col cols="4" class="green lighten-3">
                        <small class="text-uppercase black--text"
                          >Date of birth</small
                        >
                      </v-col>
                      <v-col cols="8" class="d-flex">
                        <v-text-field
                          type="date"
                          v-model="claim.date_of_birth"
                          autocomplete
                          placeholder="Enter date of birth (readonly)"
                          required
                          outlined
                          readonly
                          :rules="validationRule('Date of birth is required')"
                          class="w-100 mb-0"
                          color="green darken-4"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- ID number -->
                    <v-row
                      justify="start"
                      align="stretch"
                      class="border border-secondary rounded-0"
                    >
                      <v-col cols="4" class="green lighten-3">
                        <small class="text-uppercase black--text"
                          >identification number
                        </small>
                      </v-col>
                      <v-col cols="8" class="d-flex">
                        <v-text-field
                          v-model="claim.unique_id"
                          autocomplete
                          placeholder="Enter ID number"
                          required
                          outlined
                          :rules="validationRule('ID number is required')"
                          @keyup="autoFill"
                          class="w-100 mb-0"
                          color="green darken-4"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Telephone -->
                    <v-row
                      justify="start"
                      align="stretch"
                      class="border border-secondary rounded-0"
                    >
                      <v-col cols="4" class="green lighten-3">
                        <small class="text-uppercase black--text"
                          >patient telephone
                        </small>
                      </v-col>
                      <v-col cols="8" class="d-flex">
                        <v-text-field
                          type="number"
                          v-model="claim.phone_number"
                          autocomplete
                          placeholder="Enter phone number (readonly)"
                          required
                          outlined
                          readonly
                          :rules="validationPhoneRule()"
                          class="w-100 mb-0"
                          color="green darken-4"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Guardian -->
                    <v-row
                      justify="start"
                      align="stretch"
                      class="border border-secondary rounded-0"
                    >
                      <v-col cols="4" class="green lighten-3">
                        <small class="text-uppercase black--text"
                          >guardian's name
                        </small>
                      </v-col>
                      <v-col cols="8" class="d-flex">
                        <v-text-field
                          v-model="claim.guardian_name"
                          autocomplete
                          placeholder="Enter guardian name (readonly)"
                          required
                          outlined
                          :rules="
                            validationRule('Guardian\'s name is required')
                          "
                          class="w-100 mb-0"
                          color="green darken-4"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Gender -->
                    <v-row
                      justify="start"
                      align="stretch"
                      class="border border-secondary rounded-0"
                    >
                      <v-col cols="4" class="green lighten-3">
                        <small class="text-uppercase black--text"
                          >gender
                        </small>
                      </v-col>
                      <v-col cols="8" class="d-flex">
                        <v-radio-group
                          v-model="claim.gender"
                          class="pl-2"
                          required
                          :mandatory="true"
                        >
                          <v-radio
                            color="green darken-4"
                            key="male"
                            label="Male"
                            value="Male"
                          ></v-radio>
                          <v-radio
                            color="green darken-4"
                            key="female"
                            label="Female"
                            value="Female"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <!-- Residential Address -->
                    <v-row
                      justify="start"
                      align="stretch"
                      class="border border-secondary rounded-0"
                    >
                      <v-col cols="4" class="green lighten-3">
                        <small class="text-uppercase black--text">
                          address
                        </small>
                      </v-col>
                      <v-col cols="8" class="d-flex">
                        <v-text-field
                          v-model="claim.address"
                          autocomplete
                          placeholder="Enter residential address"
                          required
                          outlined
                          :rules="validationRule('Address is required')"
                          class="w-100 mb-0"
                          color="green darken-4"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- <textarea
                          v-else-if="detail.type === 'textarea'"
                          v-model="detail.model"
                          autocomplete
                          :placeholder="detail.placeholder"
                          required
                        ></textarea> -->
                  </v-col>
                  <v-col cols="4">
                    <v-col
                      cols="12"
                      class="text-uppercase green lighten-3 text-uppercase py-1 px-2"
                    >
                      <small>Patient's/Guardian's signature</small>
                    </v-col>
                    <v-col class="border h-75"></v-col>
                    <v-col
                      cols="12"
                      class="green lighten-3 text-uppercase py-1 px-2"
                    >
                      <small>Date</small>
                    </v-col>
                    <v-col cols="12" class="py-1 border">
                      <v-text-field
                        type="date"
                        v-model="claim.date_created"
                        :rules="[(v) => !!v || 'Date is required']"
                        required
                        outlined
                        class="w-100 align-self-stretch"
                      ></v-text-field>
                    </v-col>
                  </v-col>
                </v-row>
                <!-- end patient detail field -->

                <!-- presenting complaints field -->
                <v-row justify="start" class="mt-4 mr-auto">
                  <v-col cols="12" class="green darken-2">
                    <small class="text-uppercase white--text"
                      >presenting complaints</small
                    >
                  </v-col>
                  <v-col cols="12" class="p-0">
                    <v-textarea
                      class="w-100 h-100 border p-2"
                      :rules="[(v) => !!v || 'This field is required']"
                      v-model="claim.patient_complaints"
                      autocomplete
                      placeholder="Specify complains here..."
                      required
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>
                <!-- end presenting complaints field -->

                <!-- examination findings field -->
                <v-row justify="start" class="mt-4 mr-auto">
                  <v-col cols="12" class="green darken-2">
                    <small class="text-uppercase white--text"
                      >examination findings</small
                    >
                  </v-col>
                  <v-col cols="12" class="p-0 mr-auto">
                    <v-textarea
                      class="w-100 h-100 border p-2"
                      :rules="[(v) => !!v || 'This field is required']"
                      v-model="claim.examination_findings"
                      placeholder="Specify medical examination findings here..."
                      required
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>
                <!-- examination findings field -->

                <!-- diagnosis field -->
                <v-row justify="start" align="center" class="mt-4 mr-auto">
                  <v-col cols="12" class="green darken-2">
                    <small class="text-uppercase white--text"
                      >diagnosis (please)</small
                    >
                  </v-col>
                  <v-col cols="12" class="green lighten-2 mt-2">
                    <small class="text-uppercase black--text"
                      >outpatient consultations</small
                    >
                  </v-col>
                  <v-col cols="4" class="p-0 mt-2">
                    <v-select
                      :items="outpatients"
                      v-model="claim.out_patient_consultation"
                      placeholder="-Select-"
                      outlined
                      required
                      :rules="[(v) => !!v || 'Please select an option']"
                    ></v-select>
                  </v-col>
                  <v-col cols="4" class="p-0 ml-4">
                    <small class="font-weight-bold"
                      >Please select relevant options here.</small
                    >
                  </v-col>
                  <v-col cols="12" class="green lighten-2 mt-2">
                    <small class="text-uppercase black--text">management</small>
                  </v-col>
                  <v-col cols="4" class="p-0 mt-2">
                    <v-select
                      :items="managements"
                      v-model="claim.management"
                      placeholder="-Select-"
                      :rules="[(v) => !!v || 'Please select an option']"
                      outlined
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="4" class="p-0 ml-4">
                    <small class="font-weight-bold"
                      >Please select relevant options here.</small
                    >
                  </v-col>
                  <v-col cols="12" class="green lighten-2 mt-2">
                    <small class="text-uppercase black--text"
                      >family planning</small
                    >
                  </v-col>
                  <v-col cols="4" class="p-0 mt-2">
                    <v-select
                      :items="familyPlanning"
                      v-model="claim.family_planning"
                      placeholder="-Select-"
                      :rules="[(v) => !!v || 'Please select an option']"
                      outlined
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="4" class="p-0 ml-4">
                    <small class="font-weight-bold"
                      >Please select relevant options here.</small
                    >
                  </v-col>
                </v-row>
                <!-- end diagnosis field -->

                <!-- drugs given field -->
                <v-row justify="start" class="mt-4 mr-auto">
                  <v-col cols="12" class="green darken-2">
                    <small class="text-uppercase white--text"
                      >drugs given</small
                    >
                  </v-col>
                  <v-col cols="8" class="p-0 mr-auto">
                    <v-textarea
                      rows="1"
                      class="w-100 h-100 border p-2"
                      v-model="claim.drugs_given"
                      placeholder="Specify drugs administered here..."
                      :rules="[(v) => !!v || 'This field is required']"
                      required
                      outlined
                    ></v-textarea>
                  </v-col>
                  <v-col cols="4" class="p-0">
                    <v-text-field
                      type="number"
                      class="w-100 h-100 border p-2"
                      v-model="claim.amount_claimed"
                      placeholder="Total cost for drugs (in numbers)"
                      :rules="[
                        (v) => !!v || 'This field is required',
                        (v) =>
                          !isNaN(parseFloat(v)) || 'Please enter a valid amout',
                      ]"
                      required
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- end drugs given -->

                <!-- caregiver details -->
                <v-row justify="start" class="mt-4 mr-auto">
                  <v-col cols="12" class="green darken-2">
                    <small class="text-uppercase white--text"
                      >care giver's detail</small
                    >
                  </v-col>
                </v-row>
                <v-row justify="start" align="stretch">
                  <v-col cols="8">
                    <v-row
                      v-for="(detail, index) in careGiverDetails"
                      :key="index"
                      justify="start"
                      align="stretch"
                      class="border border-secondary rounded-0"
                    >
                      <v-col cols="4" class="green lighten-3">
                        <small class="text-uppercase black--text">{{
                          detail.field
                        }}</small>
                      </v-col>
                      <v-col cols="8" class="p-0 d-flex align-items-stretch">
                        <v-text-field
                          v-if="
                            detail.type === 'text' ||
                            detail.type === 'date' ||
                            detail.type === 'tel' ||
                            detail.type === 'number'
                          "
                          :type="detail.type"
                          v-model="detail.model"
                          :placeholder="detail.placeholder"
                          :rules="detail.validationRule"
                          class="p-2 w-100 align-self-stretch"
                          required
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="4">
                    <v-col
                      cols="12"
                      class="text-uppercase green lighten-3 text-uppercase py-1 px-2"
                    >
                      <small>Patient's/Guardian's signature</small>
                    </v-col>
                    <v-col class="border h-50"></v-col>
                    <v-col
                      cols="12"
                      class="green lighten-3 mb-2 text-uppercase py-1 px-2"
                    >
                      <small>Phone</small>
                    </v-col>
                    <v-col cols="12" class="border py-0 px-0">
                      <v-text-field
                        type="number"
                        placeholder="Health worker's Phone Number"
                        v-model="claim.caregiver_phone_number"
                        class="px-2 w-100 align-self-stretch"
                        :rules="validationPhoneRule()"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-col>
                </v-row>
                <!-- end caregiver details -->

                <v-row class="mt-8 mb-4">
                  <v-col cols="5" class="p-0">
                    <!-- show progressbar if loading -->
                    <v-progress-circular
                      v-if="isLoading"
                      :width="4"
                      :size="50"
                      color="green darken-4"
                      indeterminate
                    ></v-progress-circular>
                    <!-- form submit button -->
                    <v-btn
                      v-else
                      medium
                      type="submit"
                      color="green darken-4"
                      class="white--text text-capitalize w-25"
                      >Done</v-btn
                    >
                  </v-col>
                  <v-col v-if="error">
                    <p class="red--text text-center w-100">
                      Failed to create claim, please ensure all fields are
                      properly filled/selected and also check your internet
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "claim-form",
  created() {
    // Initialize batch name in dropdown on component creation
    this.claim_batch = this.batches.find(
      (batch) => batch.id === Number(this.$route.params.batch_id)
    ).name;

    this.loadAppData();
    // const appData = await this.loadAppData();
    // if (appData.every((data) => data === true)) {
    //   this.loading = false;
    //   return;
    // } else {
    //   this.loading = false;
    //   // Clear/Reset LocalStorage if application data failed to load
    //   this.error.status = true;
    //   this.error.message = `
    //   An error while loading this site,
    //   try refreshing your browser URL or click the refresh button below,
    //   if this page still persist please check your internet connection
    //   and reload this page again`;
    //   localStorage.clear();
    //   return;
    // }
  },
  data() {
    return {
      // loading: true,
      // error: {
      //   status: false,
      //   message: "",
      // },
      claim_batch: "",
      error: false,
      claim: {
        last_name: "",
        first_name: "",
        date_of_birth: "",
        date_created: "",
        unique_id: "",
        phone_number: "",
        gender: "",
        address: "",
        claim_batch: "",
        guardian_name: "",
        patient_complaints: "",
        examination_findings: "",
        out_patient_consultation: "",
        management: "",
        family_planning: "",
        drugs_given: "",
        amount_claimed: "",
        caregiver_surname: "",
        caregiver_firstname: "",
        caregiver_date_uploaded: "",
        caregiver_health_facility: "",
        caregiver_phone_number: "",
      },
      careGiverDetails: [
        {
          field: "surname",
          type: "text",
          placeholder: "Enter care giver surname",
          model: "",
          validationRule: [(v) => !!v || "Surname is required"],
        },
        {
          field: "first name",
          type: "text",
          placeholder: "Enter care giver firstname",
          model: "",
          validationRule: [(v) => !!v || "First name is required"],
        },
        {
          field: "date",
          type: "date",
          placeholder: "Enter care giver date of registration",
          model: "",
          validationRule: [(v) => !!v || "Registration date required"],
        },
        {
          field: "health facility",
          type: "text",
          placeholder: "Enter care giver health facility",
          model: "",
          validationRule: [(v) => !!v || "Health facility is required"],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      batches: "claimModule/batches",
      outpatients: "claimModule/consultations",
      managements: "claimModule/managements",
      familyPlanning: "claimModule/familyPlannings",
      isLoading: "claimModule/isLoading",
      getEnrolleeByID: "enrollmentModule/getEnrolleeByID",
    }),
    getBatchNames() {
      return this.batches.map((batch) => batch.name);
    },
  },
  methods: {
    ...mapActions({
      fetchBatches: "claimModule/fetchBatches",
      createClaim: "claimModule/createClaim",
      fetchActiveEnrollees: "enrollmentModule/fetchActiveEnrollees",
      fetchConsultations: "claimModule/fetchConsultations",
      fetchFamilyPlannings: "claimModule/fetchFamilyPlannings",
      fetchManagements: "claimModule/fetchManagements",
      fetchSchedules: "claimModule/fetchSchedules",
    }),
    async loadAppData() {
      return Promise.all([
        this.fetchBatches(),
        this.fetchConsultations(),
        this.fetchFamilyPlannings(),
        this.fetchManagements(),
        this.fetchSchedules(),
      ]);
    },
    validationRule(description) {
      return [(v) => !!v || description];
    },
    validationPhoneRule() {
      return [
        (v) => !!v || "Phone number is required",
        (v) => !isNaN(parseFloat(v)) || "Enter a valid phone number",
        // (v) => !!v.startsWith("+234") || "Phone numbers must begin with +234",
        (v) => !!(v.length === 11) || "Phone numbers must be 11 digits long",
      ];
    },
    // Auto-fill form input if Unique ID matches data
    autoFill(event) {
      // get enrollee that matches ID entered
      const enrollee = this.getEnrolleeByID(event.target.value);
      if (enrollee) {
        const { first_name, last_name, json, gender } = enrollee;
        const extraData = JSON.parse(json);
        const {
          phone: phone_number,
          date_of_birth,
          address,
          guardian_first_name,
          guardian_last_name,
        } = extraData;
        this.claim = {
          ...this.claim,
          first_name,
          last_name,
          gender,
          phone_number,
          date_of_birth,
          address,
          guardian_name: `${guardian_first_name} ${guardian_last_name}`,
        };
      }
    },
    async handleClaimSubmission() {
      // Create claim if form is valid
      if (this.$refs.form.validate()) {
        let caregiver_surname,
          caregiver_firstname,
          caregiver_date_uploaded,
          caregiver_health_facility;
        // caregiver initialization
        [
          caregiver_surname,
          caregiver_firstname,
          caregiver_date_uploaded,
          caregiver_health_facility,
        ] = this.careGiverDetails.map((detail) => detail.model);

        // update claim details
        this.claim = {
          ...this.claim,
          caregiver_surname,
          caregiver_firstname,
          caregiver_date_uploaded,
          caregiver_health_facility,
        };

        // assign batch this claim is to be registered under
        const selectedBatch = this.batches.find(
          (batch) => batch.name === this.claim_batch
        );

        if (selectedBatch) {
          this.claim.claim_batch = selectedBatch.id;

          const response = await this.createClaim({ data: this.claim });
          // Update batches state from server
          await this.fetchBatches();
          if (response["data"]) {
            this.$router.push({
              name: "view-batch-claim-list",
              params: {
                batch_id: selectedBatch.id,
              },
            });
            return;
          }
          this.error = true;
        }
      }
    },
  },
};
</script>

<style scoped>
td {
  background-color: #e6e6e6 !important;
}
th {
  background-color: #707070 !important;
}
</style>
